<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";

import { getScopeId } from "@telia/b2b-customer-scope";

import { translateMixin, translateSetup } from "./locale";

import { trackGA4 } from "./utils/trackGA4";
import { getIsLoggedIn } from "./utils/loggedInService";

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;
const scopeId = ref("");
const isLoggedIn = ref(false);

onBeforeMount(async () => {
  scopeId.value = (await getScopeId()) ?? "";
  isLoggedIn.value = (await getIsLoggedIn()) ?? false;
});

const legalItemsJson = computed(() => {
  return [
    {
      href: `/foretag/mybusiness/${scopeId.value}/release-notes`,
      name: t("RELASE_NOTES"),
    },
    {
      href: "/foretag/om/integritetspolicy",
      name: t("PRIVACY_POLICY"),
    },
    {
      href: "#cookie-consent",
      name: t("COOKIE_CONSENT"),
    },
    {
      href: "/foretag/om/tekniska-krav",
      name: t("ABOUT_SITE"),
    },
  ];
});

const socials = computed(() => {
  return [
    {
      link: "https://www.linkedin.com/company/teliacompany/",
      label: "LINK_TO_LINKEDIN",
      icon: "linkedin",
    },
    {
      link: "https://facebook.com/telia",
      label: "LINK_TO_FACEBOOK",
      icon: "facebook",
    },
    {
      link: "https://twitter.com/TeliaSverige",
      label: "LINK_TO_TWITTER",
      icon: "twitter",
    },
    {
      link: "https://youtube.com/teliaforetag",
      label: "LINK_TO_YOUTUBE",
      icon: "youtube",
    },
  ];
});

const getLinkHash = (element) => {
  if (element?.tagName?.toUpperCase() !== "A") {
    return false;
  }
  if (element?.hash) {
    return element.hash;
  }

  try {
    return new URL(element.href).hash;
  } catch {
    return "";
  }
};

const linkTextContains = (event, str: string) => {
  return event.composedPath().some((item) => item.textContent === str);
};

const linkHrefContains = (event: PointerEvent, str: string) => {
  return event.composedPath().some((element) => getLinkHash(element) === str);
};

const onLegalClick = (event: PointerEvent) => {
  if (linkHrefContains(event, "#cookie-consent")) {
    event.preventDefault();
    openConsentPreferences();
    return;
  }

  if (linkTextContains(event, legalItemsJson.value[0].name)) {
    trackGA4(legalItemsJson.value[0].name, isLoggedIn.value);
    return;
  }

  if (linkTextContains(event, legalItemsJson.value[1].name)) {
    trackGA4(legalItemsJson.value[1].name, isLoggedIn.value);
    return;
  }

  if (linkTextContains(event, legalItemsJson.value[3].name)) {
    trackGA4(legalItemsJson.value[3].name, isLoggedIn.value);
    return;
  }
};

const openConsentPreferences = () => {
  window.websiteCookieConsent?.openConsentPreferences?.();
};
</script>

<template>
  <telia-footer hide-top>
    <section slot="social">
      <telia-style-link
        v-for="social in socials"
        :key="social.link"
        variant="navigation"
        dark-background
        disable-visited
        ensure-click-area
      >
        <a :href="social.link" :aria-label="t(social.label)">
          <telia-icon :name="social.icon"></telia-icon>
        </a>
      </telia-style-link>
    </section>
    <section slot="legal">
      <telia-footer-legal
        :items="JSON.stringify(legalItemsJson)"
        @click="onLegalClick"
        copyright="© Telia Sverige AB"
      >
        <div slot="contacts">
          <telia-list list-tag="ul">
            <li>556430-0142</li>
            <li>Box 50077</li>
            <li>973 22 Luleå</li>
            <li>Säte: Stockholm</li>
          </telia-list>
        </div>
      </telia-footer-legal>
    </section>
  </telia-footer>
</template>

<style lang="scss" scoped></style>
