import { track } from "@telia/b2x-google-analytics";

export const trackGA4 = async (click_text: string, logged_in: boolean) => {
  const w = typeof window !== "undefined" ? window : undefined;
  const pathname = w?.location?.pathname;

  if (typeof pathname === "undefined" || !pathname.startsWith("/foretag")) return;

  const page_type = getPageType(pathname);
  const page_segment = getPageSegment(pathname);

  track("navigation_interaction", {
    page_segment,
    business_area: "b2b",
    page_type,
    logged_in,
    click_type: "footer",
    click_text,
    click_action: "click",
  });
};

const getPageType = (url: string) => {
  switch (true) {
    case testUrl("/foretag/mybusiness/(\\d+)/?$", url):
      return "start";
    case testUrl("/foretag/mybusiness/(\\d+)/fakturor", url):
      return "invoice";
    case testUrl("/foretag/mybusiness/(\\d+)/hantera", url):
      return "manage";
    case testUrl("/foretag/mybusiness/(\\d+)/support", url):
      return "support";
    case testUrl("/foretag/mybusiness/(\\d+)/bestall", url):
      return "order";
    case testUrl("/foretag/mybusiness/(\\d+)/release-notes", url):
      return "article";
    default:
      return "unknown";
  }
};

const getPageSegment = (pathname: string) => {
  switch (true) {
    case pathname.startsWith("/foretag/mybusiness"):
      return "mybusiness";
    case pathname.startsWith("/foretag/support"):
      return "support";
    default:
      return "other";
  }
};

const testUrl = (regex: string, url: string) => {
  return new RegExp(regex).test(url);
};
