<script setup lang="ts">
import type { Ref } from "vue";
import { FlagProvider } from "@unleash/proxy-client-vue";
import type { UnleashClient } from "@unleash/proxy-client-vue";
import B2bFooterVue3 from "./b2b-footer-vue3.vue";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-vue3/dist/b2b-unleash-client-vue3.esm.js";

const client: Ref<UnleashClient | null> = useUnleashClientWithScopeId("b2b-footer-vue3");
</script>

<template>
  <FlagProvider v-if="client" :unleash-client="client" :start-client="false">
    <B2bFooterVue3></B2bFooterVue3>
  </FlagProvider>
</template>
