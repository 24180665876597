import { h, createApp } from "vue";

import singleSpaVue from "single-spa-vue";
import "./set-public-path";

import App from "./toggle-provider.vue";

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {});
    },
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
